import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Text, Box, Badge } from "../../components/Core";

import styled from "styled-components";

import titleImage from "../../assets/image/jpeg/mailbox.jpg";
import iconQuote from "../../assets/image/png/quote-icon.png";
import styles from '../../assets/styles/blog.module.css';

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }
  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
`;

const BadgePost = ({ children }) => (
    <Badge
        mr={3}
        mb={3}
        bg="#eae9f2"
        color="#696871"
        fontSize="16px"
        px={3}
        py={2}
    >
        {children}
    </Badge>
);


const BlogDetails = () => {
    return (
        <>
            <PageWrapper footerDark>
                <Section className="pb-0">
                    <div className="pt-5"></div>
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col lg="12">
                                <Title variant="hero">
                                    Virtual mailboxes – how this service benefits remote workers
                </Title>
                                <Box className="d-flex justify-content-center">
                                    <Text mr={3}>Nov 7, 2020
                  </Text>
                                    <Text>Michael Tippett
                  </Text>
                                </Box>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Section className="pb-0 pt-4">
                    <Container>
                        <Row>
                            <Col lg="12" className="mb-5">
                                {/* <!-- Blog section --> */}
                                <Post>
                                    <div>
                                        <img src={titleImage} alt="Virtual mailbox" className={styles.titleImage} />
                                    </div>


                                        <p>Plenty of businesses these days don’t require a dedicated office space to run. For many small- and medium-sized businesses, for example, working on the move or from home is not only possible but also practical. Business owners who run these types of enterprises have the luxury of working from practically anywhere in the world.</p>



                                        <p>However, one of the essential requirements of owning a company is to have a legitimate business address. So how can you do that when you’re a remote worker or digital nomad?</p>



                                        <p>To cater to entrepreneurs and digital nomads, virtual mailboxes or virtual offices are on the rise and gaining momentum. Virtual mailboxes allow you to work the way you want and where you want while giving you a proper mailing service as you would if you had a physical office.</p>



                                        <p>If your business or job requires flexibility, then having a virtual mailbox is an efficient service you would want to acquire. Here are some reasons you need to get one yourself:</p>



                                        <p><strong>But first, how does a virtual mailbox work?</strong></p>



                                        <p>A virtual mailbox is a service that provides your business with a physical address. That way, even if you work remotely, you will still be able to receive physical mail and packages just like you would if you had an official office address.</p>



                                        <p>This is an extremely convenient service because the provider receives your package on your behalf while you’re away. You’ll receive a notification when a package is collected, and you’ll have different options to claim your mail.</p>



                                        <p>For example, you have the option to allow the provider to open your letter, scan it, and send you a digital copy. Alternatively, you can sign up for a mail forwarding option to have your mail and parcels sent to your home address.</p>



                                        <p><strong>All your mail in one safe place while you’re on the go</strong></p>



                                        <p>An advantage of having a virtual mailbox is that you are guaranteed a safe place for incoming mail and packages. If you’re always on the move, having your mail and packages sent to your home may cause several issues. For instance, parcels sitting at your doorstep while you are away are at risk of getting stolen or damaged.</p>



                                        <p>On the other hand, virtual mailboxes will receive all your essential documents for you while you’re gone. Once you’re ready to receive your mail, you can use their mail forwarding services to secure your packages at home.</p>



                                        <p><strong>You’ll have a professional business address</strong></p>



                                        <p>Some businesses are online-based and don’t require a physical office to operate. However, if you need clients to send in relevant documents or if you have to send parcels to partners and customers, using your home address isn’t a safe choice.</p>



                                        <p>Virtual mailboxes are incredibly convenient because they give you added security and are extremely cost-efficient. Having to go to authorities to report a theft and other issues is a burden and may give you additional expenses. Getting virtual mailboxes will eliminate these risks and allow you to work remotely with much ease.</p>



                                        <p><strong>You can manage your mail wherever</strong></p>



                                        <p>If your job requires you to move from city to city, having to change your shipping address could be quite a hassle. The great thing about virtual mailboxes is that it gives your clients and senders the illusion of your permanency in one place. It avoids confusion and the trouble of having to tell your clients of the changes in your location.</p>



                                        <p>Once you’re settled, you can have your mail forwarded to you safely and with ease. If you need to receive urgent mail, you can ask your virtual mailbox assistant to open your letters and have them scanned.</p>



                                        <p><strong>Conclusion</strong></p>



                                        <p>There are more advantages to having virtual mailboxes, and these are just a few. Being able to operate remotely and conveniently are characteristics that digital nomads and business share. As such, virtual mailboxes make it easy for them to work while being constantly on the move. All of its features, especially parcel forwarding, contribute to the success of a digital nomad’s lifestyle.</p>



                                        <p>Are you working remotely and need to manage your mail and parcels? HotSnail is a virtual office that offers <a href="http://hotsnail.com.au/">parcel forwarding</a>. We provide professional and reliable fax, package, and mail forwarding and redirection services. Talk to our team today and ask about how we can help you.</p>


                                </Post>
                                <Box className="d-flex" mt={4}>
                                    <BadgePost>Virtual mailbox</BadgePost>
                                    <BadgePost>Parcel forwarding</BadgePost>
                                    <BadgePost>Mail Scanning</BadgePost>
                                </Box>

                            </Col>
                        </Row>
                    </Container>
                </Section>
            </PageWrapper>
        </>
    );
};
export default BlogDetails;
